import React, { Component } from "react";
import BlockLeftBottom from "../media/BlockLeftBottom.svg";
import Button from "../components/button";
import BlockLeftUp from "../media/BlockLeftUp.svg";
import BlockRightBottom from "../media/BlockRightBottom.svg";
import "../stylesheets/home.css";
import RightArrow from "../media/rightArrow.svg";
import LeftArrow from "../media/leftArrow.svg";
import AssetManagement from "../media/assetManagement.svg";
import Decentralized from "../media/decentralized.svg";
import Security from "../media/security.svg";
import Coenq from "../media/Coenq.svg";
import Dapplogix from "../media/Dapplogix.svg";
import ESCI from "../media/ESCI.svg";
import Eduwizz from "../media/Eduwizz.png";
import Foddy from "../media/Foddy.png";
import Arcafab from "../media/Arcafab.png";
import LandingImage from "../media/LandingImage.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Navbar from "../components/navbar";
//json imports
import TestimonialsData from "../testimonials.json";
class Home extends Component {
  state = {
    testimonials: [],
  };
  t2content = [
    {
      subHeading: "Project Management",
      body: "Manage your projects with smart contracts, develop roadmaps for deploying the solution, and remove the hassles of analytics and meet deadlines with confidence.",
    },
    {
      subHeading: "DeFi",
      body: "Unlock access to global customers by leveraging Defi solutions, use creditworthiness to identify business opportunities, reduce costs, and build a secure environment for your business.",
    },
    {
      subHeading: "Digital Marketing",
      body: "Reach your target customers with accurate and secure data, reduce your campaign spend, take advantage of cost-effective campaigns, and move away from fraudulent data.",
    },
  ];
  t3content = [
    {
      image: AssetManagement,
      subHeading: "Asset Management",
      body: "-BlockForTrust customizes the full life cycle execution plan and reduce guarantees for people to work together. \n\n -We provide comprehensive digital assets, OTC channels, asset management.",
    },
    {
      image: Security,
      subHeading: "Security",
      body: "-We provide decentralized systems that make your data safer from cyberattacks and ensure that you don't have to pay hackers.\n\n -We ensure that your data doesn’t get unauthorized access in transit.",
    },
    {
      image: Decentralized,
      subHeading: "Decentralized",
      body: "-We help you speed up your transactions, achieve greater operational efficiencies using decentralized ledgers.\n\n -We believe in decentralized setups that work with Distributed Ledger Technology.",
    },
  ];

  t5content = [
    {
      image: Coenq,
    },
    {
      image: Dapplogix,
    },
    {
      image: ESCI,
    },
    {
      image: Eduwizz,
    },
    {
      image: Foddy,
    },
    {
      image: Arcafab,
    },
  ];

  handleTestimonials = () => {
    this.state.testimonials = [];
    let size = TestimonialsData.testimonials.length;
    let i = 0;
    if (window.innerWidth <= 768) {
      while (i < size) {
        let temp = (
          <div key={i} className="t6InSliderDiv">
            <div className="t6EachTestimonialDiv">
              <div className="t6PicAndNameDiv">
                <img
                  className="t6ClientImg"
                  alt="img"
                  src={
                    "../media/Testimonial_" +
                    TestimonialsData.testimonials[i].givenBy.replace(" ", "") +
                    ".png"
                  }
                />
                <span className="t6GivenByName">
                  {TestimonialsData.testimonials[i].givenBy}
                </span>
                <span className="t6ClientNameText">
                  {TestimonialsData.testimonials[i].clientName}
                </span>
              </div>
              <span className="t6EachTestimonialDivReviewText">
                {TestimonialsData.testimonials[i].body}
              </span>
            </div>
          </div>
        );
        this.state.testimonials.push(temp);
        i = i + 1;
      }
    } else {
      i = 1;
      while (i < size) {
        let temp = (
          <div className="t6InSliderDiv" key={i}>
            <div className="t6EachTestimonialDiv t6EvenDiv">
              <div className="t6PicAndNameDiv">
                <img
                  className="t6ClientImg"
                  alt="img"
                  src={
                    "../media/Testimonial_" +
                    TestimonialsData.testimonials[i - 1].givenBy.replace(
                      " ",
                      ""
                    ) +
                    ".png"
                  }
                />
                <span className="t6GivenByName">
                  {TestimonialsData.testimonials[i - 1].givenBy}
                </span>
                <span className="t6ClientNameText">
                  {TestimonialsData.testimonials[i - 1].clientName}
                </span>
              </div>
              <span className="t6EachTestimonialDivReviewText">
                {TestimonialsData.testimonials[i - 1].body}
              </span>
            </div>
            <div className="t6EachTestimonialDiv t6OddDiv">
              <div className="t6PicAndNameDiv">
                <img
                  className="t6ClientImg"
                  alt="img"
                  src={
                    "../media/Testimonial_" +
                    TestimonialsData.testimonials[i].givenBy.replace(" ", "") +
                    ".png"
                  }
                />
                <span className="t6GivenByName">
                  {TestimonialsData.testimonials[i].givenBy}
                </span>
                <span className="t6ClientNameText">
                  {TestimonialsData.testimonials[i].clientName}
                </span>
              </div>
              <span className="t6EachTestimonialDivReviewText">
                {TestimonialsData.testimonials[i].body}
              </span>
            </div>
          </div>
        );
        this.state.testimonials.push(temp);
        i = i + 2;
      }
      if (i === size) {
        let temp2 = (
          <div className="t6InSliderDiv" key={i}>
            <div className="t6EachTestimonialDiv">
              <div className="t6PicAndNameDiv">
                <img
                  className="t6ClientImg"
                  alt="img"
                  src={
                    "../media/Testimonial_" +
                    TestimonialsData.testimonials[i - 1].givenBy.replace(
                      " ",
                      ""
                    ) +
                    ".png"
                  }
                />
                <span className="t6GivenByName">
                  {TestimonialsData.testimonials[i - 1].givenBy}
                </span>
                <span className="t6ClientNameText">
                  {TestimonialsData.testimonials[i - 1].clientName}
                </span>
              </div>
              <span className="t6EachTestimonialDivReviewText">
                {TestimonialsData.testimonials[i - 1].body}
              </span>
            </div>
          </div>
        );
        this.state.testimonials.push(temp2);
      }
    }
  };

  render() {
    this.handleTestimonials();
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 4000,
      nextArrow: <img className="prevNextImgs" src={RightArrow} alt="img" />,
      prevArrow: <img className="prevNextImgs" src={LeftArrow} alt="img" />,
    };

    return (
      <div className="HomeMainDiv">
        <Navbar
          products={this.props.products}
          menubarDisplayActive={this.props.menubarDisplayActive}
          menubarDisplayRemove={this.props.menubarDisplayRemove}
          menubarDisplay={this.props.menubarDisplay}
          activeLink="Home"
        />
        {/* -----------Template 1------------- */}
        <div className="t1MainDiv">
          <img src={BlockLeftBottom} className="t1bgImg1" alt="img" />
          <img src={BlockLeftUp} className="t1bgImg2" alt="img" />
          <div className="t1ContentDiv">
            <span className="t1Heading">BLOCK FOR TRUST</span>
            <span className="t1Body">
              BlockForTrust is one of the leading companies that leverages
              Blockchain technology to revolutionize business practices and fuel
              the growth of organizations.
            </span>
            <Button path="/contact" buttonText="GET IN TOUCH" />
          </div>
          <div className="t1ImgDiv">
            <img src={LandingImage} alt="img" />
          </div>
        </div>
        {/* ---------Template-2-------------- */}
        <div className="t2MainDiv">
          <img src={BlockLeftBottom} className="t2bgImg1" alt="img" />
          <div className="t2HeadingDiv">
            <span>How block chain technology can transform your business?</span>
          </div>
          <div
            className={
              this.t2content.length === 2
                ? "t2Size2TempContentDiv"
                : "t2ContentDiv "
            }
          >
            {this.t2content.map((content) => {
              let index = this.t2content.indexOf(content) + 1;
              return (
                <div
                  key={index}
                  className={
                    "t2InfoDiv t2Info" +
                    index +
                    "Div " +
                    (this.t2content.length === 2
                      ? "t2Size2TempInfo" + index + "Div"
                      : "")
                  }
                >
                  <span className={"t2SubHeading t2SubHeading" + index}>
                    {content.subHeading}
                  </span>
                  <span className={"t2Body t2Body" + index}>
                    {content.body}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
        {/* ---------Template-3-------------- */}
        <div className="t3MainDiv">
          <img src={BlockRightBottom} className="t3bgImg1" alt="img" />
          <div className="t3HeadingDiv">
            <span className="t3HeadingText">Our Solutions</span>
            <span className="t3DescriptionText">
              Find out how our solutions are helping enterprises benefit from
              blockchain technology.
            </span>
          </div>
          <div className="t3ContentDiv">
            {this.t3content.map((object) => {
              return (
                <div
                  key={this.t3content.indexOf(object)}
                  className={"t3InfoMainDiv " + this.t3content.indexOf(object)}
                >
                  <img alt="img" src={object.image} />
                  <span className="t3SubHead">{object.subHeading}</span>
                  <span className="t3SubDesc">{object.body}</span>
                </div>
              );
            })}
          </div>
        </div>
        {/* ---------Template-4-------------- */}
        <div className="t4MainDiv">
          <img src={BlockLeftUp} className="t4bgImg1" alt="img" />
          <div className="t4HeadingDiv">
            <span className="t4Heading">Our Products</span>
            <span className="t4HeadingDescription">
              Find out how our products are helping enterprises benefit from
              blockchain technology.
            </span>
          </div>
          <div className="t4ContentDiv">
            {this.props.ProductsData.products.map((product) => {
              let index = this.props.ProductsData.products.indexOf(product) + 1;
              return (
                <div
                  key={index}
                  className={
                    "t4InfoDiv " +
                    (index % 2 === 0
                      ? "t4InfoImageRightDiv"
                      : "t4InfoImageLeftDiv")
                  }
                >
                  <img
                    className="t4Image"
                    src={
                      "../media/Logo_" +
                      product.productTitle.replace(" ", "") +
                      ".svg"
                    }
                    alt="img"
                  />
                  <div className="t4ContentDescriptionDiv">
                    <span className="t4SubHeading">{product.productTitle}</span>
                    <span className="t4Description">
                      {product.productDescription}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {/* ---------Template-5-------------- */}
        <div className="t5MainDiv">
          <img src={BlockLeftBottom} className="t5bgImg1" alt="img" />
          <div className="t5HeadingDiv">
            <span className="t5HeadingText">Our Clients</span>
            <span className="t5HeadingDescriptionText">
              Driving technology for leading brands.
            </span>
          </div>
          <div className="t5ContentDiv">
            {this.t5content.map((obj) => {
              let index = this.t5content.indexOf(obj) + 1;
              return (
                <div key={index} className="image">
                  <img src={obj.image} alt="img" className="clientImages" />
                </div>
              );
            })}
          </div>
        </div>
        {/* ---------------Template-6---------------- */}
        <div className="t6MainDiv">
          <img src={BlockRightBottom} className="t6bgImg1" alt="img" />
          <div className="t6SubMainDiv">
            <span className="t6HeadingText">Testimonials</span>
            <div className="t6OutSliderDiv">
              <Slider {...settings} className="t6Slider">
                {this.state.testimonials.map((content) => {
                  return content;
                })}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
