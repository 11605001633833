import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../stylesheets/footer.css";
import Facebook from "../media/Facebook.svg";
import LinkedInImg from "../media/LinkedInLogo.svg";
import Whatsapp from "../media/Whatsapp.svg";
import Logo from "../media/Logo.svg";

class footer extends Component {
  state = {};
  address =
    this.props.ContactData.contactUs.addressLine1 +
    ", " +
    this.props.ContactData.contactUs.addressLine2 +
    ", " +
    this.props.ContactData.contactUs.state +
    ", " +
    this.props.ContactData.contactUs.country +
    ".";
  footSocial = [
    {
      image: Facebook,
      link: "https://www.facebook.com/Block-For-Trust-1044326895759019",
    },
    {
      image: LinkedInImg,
      link: "https://www.linkedin.com/company/blockfortrust-solutions/about/?viewAsMember=true",
    },
    {
      image: Whatsapp,
      link: "https://wa.me/917893059116",
    },
  ];
  footCompanyLinks = [
    {
      name: "Terms Of Service",
      link: "/termsofservice",
    },
    {
      name: "Privacy Policy",
      link: "/privacypolicy",
    },
    {
      name: "Security",
      link: "/security",
    },
  ];

  render() {
    return (
      <div className="footMainDiv">
        <div className="footIntroDiv">
          <div className="footIntroHeadingDiv">
            <img className="footIntroImg" src={Logo} alt="img" />
            <span className="footIntroHeading">BlockForTrust</span>
          </div>
          <div className="footIntroDescription">
            Avail our proven expertise in blockchain technologies
          </div>
        </div>
        <div className="footLinksDiv">
          <div className="footSocialDiv">
            {this.footSocial.map((link) => {
              let index = this.footSocial.indexOf(link) + 1;
              return (
                <a href={link.link} target="_blank">
                  <img
                    key={index}
                    className={"footSocailImg" + index}
                    src={link.image}
                    alt="img"
                  />
                </a>
              );
            })}
          </div>
          <div className="footCompanyLinks">
            {this.footCompanyLinks.map((link) => {
              let index = this.footCompanyLinks.indexOf(link) + 1;
              return (
                <Link
                  to={link.link}
                  className={"footCompanyLink" + index + " footCompanyLink"}
                >
                  <span key={index}>{link.name}</span>
                </Link>
              );
            })}
          </div>
        </div>
        <div className="footContactDiv">
          <span className="footContactHeading">Contact</span>
          <span className="footContactAddress">{this.address}</span>
          <span className="footContactPhone">
            {this.props.ContactData.contactUs.phoneNumber}
          </span>
          <span className="footContactEmail">
            {this.props.ContactData.contactUs.email}
          </span>
        </div>
      </div>
    );
  }
}

export default footer;
