import React, { Component } from "react";
import BlockLeftBottom from "../media/BlockLeftBottom.svg";
import BlockRightBottom from "../media/BlockRightBottom.svg";
import Button from "../components/button";
import "../stylesheets/collaboration.css";
import Navbar from "../components/navbar";
import CollabMainImg from "../media/CollabMainImg.svg";

class Collaboration extends Component {
  state = {
    t12isLess: true,
    t81isLess: true,
  };
  t12toggle = () => {
    let newState = { ...this.state };
    newState.t12isLess = !newState.t12isLess;
    this.setState(newState);
  };
  t81toggle = () => {
    let newState = { ...this.state };
    newState.t81isLess = !newState.t81isLess;
    this.setState(newState);
  };

  t82content = [
    {
      subHeading: "Governance &\nAudit",
      body: "Governance provides a vital role in any implementation. Our team can help you to define the Blockchain Governance.\n\n            We are there for Code review and analysis of the existing smart contract code & related components. As Audit advisor, we’ll prepare a vulnerabilities and security issues report which can build confidence and reliability in the systems.\n              ",
    },
    {
      subHeading: "R & D Labs",
      body: "We can help you study new concepts around Hyperledger blockchains, analyze the security of your application or research new types of blockchains.\n\n            We also dedicate our time on the Private Permissioned Blockchain.\n              ",
    },
  ];

  render() {
    return (
      <div className="CollabMainDiv">
        <Navbar
          products={this.props.products}
          menubarDisplayActive={this.props.menubarDisplayActive}
          menubarDisplayRemove={this.props.menubarDisplayRemove}
          menubarDisplay={this.props.menubarDisplay}
          activeLink="Collaboration"
        />
        {/* ---------------Template 11--------------- */}
        <div className="t11MainDiv">
          <img src={BlockLeftBottom} className="t11BgImg1" alt="img" />
          <span className="t11Heading">
            {"Let’s Grow\nTogether -\nCollaborate Now!"}
          </span>
          <img src={CollabMainImg} alt="img" className="t11Image" />
        </div>
        {/* ---------------------Template 12------------ */}
        <div className="t12MainDiv">
          <div className="t12SubDiv1">
            <div className="t12SubDiv1SubDiv">
              <span className="t12HeadingText">
                {"A Productive\nConsultancy"}
              </span>
              <div className="t12Bottombar"></div>
            </div>
          </div>
          <div className="t12SubDiv2">
            <span className="t12DescriptionText">
              {this.state.t12isLess
                ? "We provide you with the programming of scalable and distributed applications and custom-built protocols. Additionally, we support you throughout the entire process of integration and adaptation of blockchain technology into your already existing company network."
                : "We provide you with the programming of scalable and distributed applications and custom-built protocols. Additionally, we support you throughout the entire process of integration and adaptation of blockchain technology into your already existing company network." +
                  " " +
                  "We provide you with the programming of scalable and distributed applications and custom-built protocols. Additionally, we support you throughout the entire process of integration and adaptation of blockchain technology into your already existing company network."}
            </span>
            {"We provide you with the programming of scalable and distributed applications and custom-built protocols. Additionally, we support you throughout the entire process of integration and adaptation of blockchain technology into your already existing company network." && (
              <span className="t12ReadMoreButton" onClick={this.t12toggle}>
                {this.state.t12isLess ? "Read More" : "Read Less"}
              </span>
            )}
          </div>
        </div>
        {/* -------------------------Template-8-------------------- */}
        <div className={"t8MainDiv single "}>
          <img className={"singleImg"} src={BlockRightBottom} alt="img" />
          <div className={"t8SubDiv t8SubDivSingle"}>
            <div className="t8Heading">{"Get Trained by\nOur Experts"}</div>
            <div className="t8Bottombar"></div>
            <span className="t8Description">
              {this.state.t81isLess
                ? "We believe in spreading the knowledge and our focus is on Corporate Trainings and help companies to build expertise in this niche area.\n\n              Blockchain technologies are a very unique concept, difficult to understand. We provide all levels of training, business and technical, with a focus on what blockchains could change for your company around real life use-cases.\n              \n              "
                : "We believe in spreading the knowledge and our focus is on Corporate Trainings and help companies to build expertise in this niche area.\n\n              Blockchain technologies are a very unique concept, difficult to understand. We provide all levels of training, business and technical, with a focus on what blockchains could change for your company around real life use-cases.\n              \n              " +
                  " " +
                  "We provide you with the programming of scalable and distributed applications and custom-built protocols. Additionally, we support you throughout the entire process of integration and adaptation of blockchain technology into your already existing company network. "}
            </span>
            {
              <span className="t8ReadMoreButton" onClick={this.t81toggle}>
                {this.state.t81isLess ? "Read More" : "Read Less"}
              </span>
            }
          </div>
          <img className={"img"} src={BlockLeftBottom} alt="img" />
        </div>
        {/* --------------------Template 12--------------------- */}
        <div className="t12MainDiv">
          <div className="t12SubDiv1">
            <div className="t12SubDiv1SubDiv">
              <span className="t12HeadingText">{"A Proof Of\nConcept"}</span>
              <div className="t12Bottombar"></div>
            </div>
          </div>
          <div className="t12SubDiv2">
            <span className="t12DescriptionText">
              It’s a matter of 2 week, Trust us and we will build, develop and
              deliver a blockchain Proof of Concept (PoC) for your company and
              at your door step.
            </span>
          </div>
        </div>
        {/* --------------------Template-8---------------------- */}
        <div className={"t8MainDiv"}>
          <img className={"img"} src={BlockRightBottom} alt="img" />
          {this.t82content.map((infoDiv) => {
            return (
              <div
                className={"t8SubDiv"}
                key={this.t82content.indexOf(infoDiv)}
              >
                <div className="t8Heading">{infoDiv.subHeading}</div>
                <div className="t8Bottombar"></div>
                <span className="t8Description">{infoDiv.body}</span>
              </div>
            );
          })}
          <img className={"multipleImg"} src={BlockLeftBottom} alt="img" />
        </div>
        {/* -------------button----------- */}
        <div className="buttonDiv">
          <Button path="/contact" buttonText={"Connect Now"} />
        </div>
      </div>
    );
  }
}

export default Collaboration;
