import React, { Component } from "react";
import ContactUsImg from "../media/ContactUsImg.svg";
import BlockLeftBottom from "../media/BlockLeftBottom.svg";
import BlockLeftUp from "../media/BlockLeftUp.svg";
import Button from "../components/button";
import "../stylesheets/contact.css";
import Navbar from "../components/navbar";

class Contact extends Component {
  state = {};
  address =
    this.props.ContactData.companyName +
    ", " +
    this.props.ContactData.contactUs.addressLine1 +
    ", " +
    this.props.ContactData.contactUs.addressLine2 +
    ", " +
    this.props.ContactData.contactUs.state +
    ", " +
    this.props.ContactData.contactUs.country +
    ".";
  t14content = [
    {
      subHeading: "Address",
      body: this.address,
    },
    {
      subHeading: "Email Us",
      body: this.props.ContactData.contactUs.email,
    },
    {
      subHeading: "Call Us",
      body: this.props.ContactData.contactName
        ? this.props.ContactData.contactName +
          ": " +
          this.props.ContactData.contactUs.phoneNumber
        : this.props.ContactData.contactUs.phoneNumber,
    },
  ];
  render() {
    return (
      <div className="contactMainDiv">
        <Navbar
          products={this.props.products}
          menubarDisplayActive={this.props.menubarDisplayActive}
          menubarDisplayRemove={this.props.menubarDisplayRemove}
          menubarDisplay={this.props.menubarDisplay}
          activeLink="Contact"
        />
        {/* ---------------Template 13------------------- */}
        <div className="t13MainDiv">
          <img src={BlockLeftBottom} alt="img" className="t13BgImg1" />
          {/* <div className="t13FormDiv">
            <span className="t13FormHeading">{"Connect with Us"}</span>
            <div className="t13FormInputs">
              <input
                type="text"
                placeholder="Enter Your Name"
                className="t13NameInput"
              />
              <input
                type="email"
                placeholder="Enter a valid email address"
                className="t13EmailInput"
              />
              <textarea
                placeholder="Enter your message"
                rows="5"
                cols="30"
                className="t13MessageInput"
              />
              <Button buttonText="Submit" />
            </div>
          </div> */}
          <a
            target="_blank"
            href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=team@blockfortrust.com"
          >
            {" "}
            <img src={ContactUsImg} alt="img" className="t13Image" />
          </a>
        </div>
        {/* ------------Template 14-------------- */}
        <div className="t14MainDiv">
          <img src={BlockLeftUp} alt="img" className="t14BgImg" />
          <div className="t14ContactDiv">
            <span className="t14Heading">{"Contact Info"}</span>
            {this.t14content.map((block) => {
              let index = this.t14content.indexOf(block);
              return (
                <div className="t14BlockDiv" key={index}>
                  <span className="t14BlocHeading">{block.subHeading}</span>
                  <span className="t14Block">{block.body}</span>
                </div>
              );
            })}
          </div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2228.4972411300682!2d78.39540518675453!3d17.436613627616413!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9120fc4bf011%3A0xc3a4d95d864e3625!2sBlockfortrust%20pvt%20ltd(BFT)!5e0!3m2!1sen!2sin!4v1623557874796!5m2!1sen!2sin"
            width="450"
            height="350"
            title="Map"
            allowfullscreen=""
            loading="lazy"
            className="t14Map"
          ></iframe>
        </div>
      </div>
    );
  }
}

export default Contact;
