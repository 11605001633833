import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../stylesheets/footerMobile.css";
import Logo from "../media/Logo.svg";
import FacebookImg from "../media/Facebook.svg";
import WhatsappImg from "../media/Whatsapp.svg";
import LinkedInImg from "../media/LinkedInLogo.svg";
class footerMobile extends Component {
  state = {};
  address =
    this.props.ContactData.contactUs.addressLine1 +
    ", " +
    this.props.ContactData.contactUs.addressLine2 +
    ", " +
    this.props.ContactData.contactUs.state +
    ", " +
    this.props.ContactData.contactUs.country +
    ".";
  footSocial = [
    {
      image: FacebookImg,
      link: "https://www.facebook.com/Block-For-Trust-1044326895759019",
    },
    {
      image: LinkedInImg,
      link: "https://www.linkedin.com/company/blockfortrust-solutions/about/?viewAsMember=true",
    },
    {
      image: WhatsappImg,
      link: "https://wa.me/917893059116",
    },
  ];
  footCompanyLinks = [
    {
      name: "Terms Of Service",
      link: "/termsofservice",
    },
    {
      name: "Privacy Policy",
      link: "/privacypolicy",
    },
    {
      name: "Security",
      link: "/security",
    },
  ];

  render() {
    return (
      <div className="footMMainDiv">
        <div className="footMDiv1">
          <div className="footMIntroHeadingDiv">
            <img className="footMIntroImg" src={Logo} alt="img" />
            <span className="footMIntroHeading">BlockForTrust</span>
          </div>
          <div className="footMIntroDescription">
            Avail our proven expertise in blockchain technologies
          </div>
          <div className="footMCompanyLinks">
            {this.footCompanyLinks.map((link) => {
              let index = this.footCompanyLinks.indexOf(link) + 1;
              return (
                <Link
                  to={link.link}
                  className={"footMCompanyLink footMCompanyLink" + index}
                >
                  <span key={index}>{link.name}</span>
                </Link>
              );
            })}
          </div>
        </div>
        <div className="footMDiv2">
          <div className="footMContactDiv">
            <span className="footMContactHeading">Contact</span>
            <span className="footMContactAddress">{this.address}</span>
            <span className="footMContactPhone">
              {this.props.ContactData.contactUs.phoneNumber}
            </span>
            <span className="footMContactEmail">
              {this.props.ContactData.contactUs.email}
            </span>
          </div>
          <div className="footMSocialDiv">
            {this.footSocial.map((link) => {
              let index = this.footSocial.indexOf(link) + 1;
              return (
                <a href={link.link} target="_blank">
                  <img
                    key={index}
                    className={"footMSocailImg" + index}
                    src={link.image}
                    alt="img"
                  />
                </a>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default footerMobile;
