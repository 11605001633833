import React, { Component } from "react";

import "../stylesheets/footerMobile375.css";
import Logo from "../media/Logo.svg";
import FacebookImg from "../media/Facebook.svg";
import WhatsappImg from "../media/Whatsapp.svg";
import LinkedInImg from "../media/LinkedInLogo.svg";
import { Link } from "react-router-dom";
class footerMobile extends Component {
  state = {};
  address =
    this.props.ContactData.contactUs.addressLine1 +
    ", " +
    this.props.ContactData.contactUs.addressLine2 +
    ", " +
    this.props.ContactData.contactUs.state +
    ", " +
    this.props.ContactData.contactUs.country +
    ".";
  footSocial = [
    {
      image: FacebookImg,
      link: "https://www.facebook.com/Block-For-Trust-1044326895759019",
    },
    {
      image: LinkedInImg,
      link: "https://www.linkedin.com/company/blockfortrust-solutions/about/?viewAsMember=true",
    },
    {
      image: WhatsappImg,
      link: "https://wa.me/917893059116",
    },
  ];
  footCompanyLinks = [
    {
      name: "Terms Of Service",
      link: "/termsofservice",
    },
    {
      name: "Privacy Policy",
      link: "/privacypolicy",
    },
    {
      name: "Security",
      link: "/security",
    },
  ];

  render() {
    return (
      <div className="footM375MainDiv">
        <div className="footM375IntroDiv">
          <div className="footM375IntroHeadingDiv">
            <img className="footM375IntroImg" src={Logo} alt="img" />
            <span className="footM375IntroHeading">{"BlockForTrust"}</span>
          </div>
          <div className="footM375IntroDescription">
            {"Avail our proven expertise in blockchain technologies"}
          </div>
        </div>
        <div className="footM375ContactDiv">
          <span className="footM375ContactHeading">{"Contact"}</span>
          <span className="footM375ContactAddress">{this.address}</span>
          <span className="footM375ContactPhone">
            {this.props.ContactData.contactUs.phoneNumber}
          </span>
          <span className="footM375ContactEmail">
            {this.props.ContactData.contactUs.email}
          </span>
        </div>
        <div className="footM375SocialOuterDiv">
          <div className="footM375SocialDiv">
            {this.footSocial.map((link) => {
              let index = this.footSocial.indexOf(link) + 1;
              return (
                <a href={link.link} target="_blank">
                  <img
                    key={index}
                    className={"footM375SocailImg" + index}
                    src={link.image}
                    alt="img"
                  />
                </a>
              );
            })}
          </div>
        </div>
        <div className="footM375CompanyOuterLinks">
          <div className="footM375CompanyLinks">
            {this.footCompanyLinks.map((link) => {
              let index = this.footCompanyLinks.indexOf(link) + 1;
              return (
                <Link
                  to={link.link}
                  className={"footM375CompanyLink footM375CompanyLink" + index}
                >
                  <span key={index}>{link.name}</span>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default footerMobile;
