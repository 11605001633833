import React, { Component } from "react";
import Navbar from "../components/navbar";
import "../stylesheets/termsOfService.css";
class TermsOfService extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <Navbar
          products={this.props.products}
          menubarDisplayActive={this.props.menubarDisplayActive}
          menubarDisplayRemove={this.props.menubarDisplayRemove}
          menubarDisplay={this.props.menubarDisplay}
        />
        <div className="TOSMainDiv">
          <h1 className="mainHeading">Terms of Service</h1>
          <span className="descriptionInfo">
            By accessing the webpages BlockForTrust Solutions Private Limited
            (BFT) (“the Website”) you (“you, your or yourself”) are agreeing to
            accept and comply with the terms and conditions stated below (“Terms
            of Use”). You should read the entire Terms of Use before using this
            Website carefully before using this Website.
          </span>
          <h3 className="sideHeadings">Use of the Website materials</h3>
          <span className="descriptionInfo">
            The purpose of this Website is to provide its visitors with
            information about BlockForTrust Solutions Private Limited (BFT)
            projects, services, news. {"\n"} BlockForTrust Solutions Private
            Limited (BFT) makes reasonable efforts to ensure that the Website is
            constantly available 24/7. However, there might be occasions when
            the working of Website can be interrupted for maintenance, repairs
            or due to failure of telecommunications links and hardware
            equipment. Immediate and prompt steps will be taken by the Company
            to minimize such disruptions.
          </span>
          <h3 className="sideHeadings">Personal data</h3>
          <span className="descriptionInfo">
            The Company will process your personal data in accordance with the
            Privacy Policy and notifications published on the company’s Website
            (blockfortrust.com). We recommend that you read the Privacy Policy
            so that you understand the purpose of the data that we collect, how
            we use it, and with whom we share your data.
          </span>
          <h3 className="sideHeadings">Intellectual Property</h3>
          <span className="descriptionInfo">
            Unless otherwise specified, information/materials contained in the
            Website/application are subject to copyright protection unless where
            stated otherwise. You can view, download and print extracts from the
            Website for personal or any internal use within your organization,
            but should not otherwise copy, reproduce or re-distribute the
            contents of these pages in whole or in parts without our prior
            written consent. All other rights are reserved.
          </span>
          <h3 className="sideHeadings">No representations</h3>
          <span className="descriptionInfo">
            The Website/application and all of the information and materials
            within it have been prepared with the aim of providing key general
            information only and do not constitute professional (financial,
            legal, or otherwise) advice in relation to any particular situation
            and should not be used for such situations. We do not accept
            responsibility for actions based on the materials/information
            contained herein.
            <br />
            <br />
            We do not make representations for any external or third-party
            website that you may access through our Website. Wherever the
            Website contains references or links to other websites (“External
            Websites”). BlockForTrust Solutions Private Limited (BFT) does not
            control these External Sources or third-party sites or the content
            associated with them. You agree that we are in no way responsible or
            liable for the third-party sites /External Websites referenced from
            the Website and that you shall be informed of all such risks
            associated with the use of the contents provided.
          </span>
          <h3 className="sideHeadings">Indemnification</h3>
          <span className="descriptionInfo">
            You agree to indemnify, defend and hold BlockForTrust Solutions
            Private Limited (BFT), its affiliates and service providers, and
            each of their respective officers, directors, partners, agents,
            employees, and representatives, harmless from any claim or demand
            (including legal fees and costs and any fines, fees or penalties
            imposed by any authority of regulatory ) arising out of or related
            to (i) your breach of these Terms of Use (ii) your use of
            materials/information provided on the Website, or (iii) your
            violation of any law, rule, or regulation, or the rights of any
            third party.
          </span>
          <h3 className="sideHeadings">Acknowledgment</h3>
          <span className="descriptionInfo">
            We make sincere endeavors to ensure that the information provided on
            our website is accurate. However, you acknowledge and agree that
            BlockForTrust Solutions Private Limited (BFT) and its employees
            accept no liability whatsoever for any loss or damage caused by or
            arising directly or indirectly in relation with any use or
            dependence on the contents of the{" "}
            {<a href="/">www.blockfortrust.com</a>} except to the extent where
            such liability cannot be excluded by law.
            <br />
            <br /> You acknowledge and agree that you are responsible for all
            actions taken within the Website by it, and its administrators, all
            actions you direct BlockForTrust Solutions Private Limited (BFT) and
            its employees to undertake on your behalf. <br />
            <br />
            You agree that the Company will not be liable in any event to you or
            any other party for the interruption or non-availability of the
            Website. You agree to indemnify and hold BlockForTrust Solutions
            Private Limited (BFT), its officers, agents, partners, and employees
            harmless from any claim or demand, including reasonable legal cost
            or fees, made by any third party due to or arising out of
            BlockForTrust Solutions Private Limited (BFT)’s materials or usage
            of the Website, or its alleged violation of any third party rights.
          </span>
          <h3 className="sideHeadings">Disclaimer</h3>
          <span className="descriptionInfo">
            The Website and information provided on the{" "}
            {<a href="/">www.blockfortrust.com</a>} are provided “as-is” without
            representation or warranty of any kind, including, without
            limitation, any implied warranties of merchantability, fitness for a
            particular purpose, usage of the trade, non-infringement course of
            dealing, course of performance, accuracy or security. Without
            limitation of the foregoing, BlockForTrust Solutions Private Limited
            (BFT) does not warrant any third party materials, services, support,
            products by parties other than BlockForTrust Solutions Private
            Limited (BFT). BlockForTrust Solutions Private Limited (BFT) agrees
            to provide any support needed for the Website and materials provided
            on the Website. However, in all such cases, no service level
            agreement shall apply.
            <br />
            <br />
            Statements and explanations of the Website and materials provided on
            the Website are made for the purpose of making information
            explanatory only; they are not meant to constitute any guarantee or
            warranty of any features contained in the website. Without
            limitation of the foregoing BlockForTrust Solutions Private Limited
            (BFT) does not represent or endorse the accuracy or reliability of
            any (a) links to web-pages of third parties contained on the
            Website, or the content available on such web-pages or (b)
            information displayed by third parties on the Website.
          </span>
          <h3 className="sideHeadings">Limitations on liability</h3>
          <span className="descriptionInfo">
            TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, YOU AGREE THAT
            BlockForTrust Solutions Private Limited (BFT) IS NOT LIABLE TO YOU
            FOR ANY LOSS OR DAMAGES OF ANY KIND (INCLUDING, WITHOUT LIMITATION,
            ANY DIRECT, SPECIAL, INDIRECT, INCIDENTAL, EXEMPLARY, CONSEQUENTIAL,
            ECONOMIC, OR PUNITIVE DAMAGES), INCLUDING, WITHOUT LIMITATION, ANY
            LOSS OR DAMAGES ARISING OUT OF OR IN ANY WAY CONNECTED WITH OR
            RELATING TO YOUR USE OF THE WEBSITE OR WITH THE DELAY OR INABILITY
            TO USE THE WEBSITE, OR FOR ANY INFORMATION, CONTENT, AND/OR SERVICES
            ACCESSED THROUGH THE WEBSITE, OR OTHERWISE ARISING OUT OF THE USE OF
            THE WEBSITE, EVEN IF BlockForTrust Technologies Private Limited
            (BFT) HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO
            EVENT AND UNDER NO CIRCUMSTANCES WILL BlockForTrust Solutions
            Private Limited (BFT) BE LIABLE TO YOU FOR ANY REASON OR ANY CAUSE
            OF ACTION WHATSOEVER.
          </span>
          <h3 className="sideHeadings">Severability</h3>
          <span className="descriptionInfo">
            If any provisions of these Terms are deemed unlawful, void, or for
            any reason is unenforceable, then that provision shall be deemed
            severable from these Terms and will not affect the validity and
            enforceability of any remaining provisions listed in the Terms of
            Use.
          </span>
          <h3 className="sideHeadings">Third-party websites </h3>
          <span className="descriptionInfo">
            BlockForTrust Solutions Private Limited (BFT) shall not be directly
            or indirectly responsible or liable for any damage or loss caused or
            alleged to be caused by or in connection with use of or reliance on
            any equipment, devices, hardware, cabling, materials, services,
            support, products, applications or licensed software supplied by
            parties other than BlockForTrust Solutions Private Limited (BFT).
            <br />
            <br />
            The Website may contain references to third-party websites which may
            contain information by our partners and third-party service
            providers. BlockForTrust Solutions Private Limited (BFT) shall not
            be held responsible for the contents of any linked website, or any
            changes or updates to such websites. You hereby further agree that
            we shall not be responsible in any way directly or indirectly or
            liable for any damage or loss caused or alleged to be caused by or
            in relation with Website Users’ use of or dependence on any content,
            material, goods, or services available on or through any such
            external website.
          </span>
          <h3 className="sideHeadings">Acceptance of the Terms of Use</h3>
          <span className="descriptionInfo">
            If you do not accept these Terms, you must refrain from using the
            Website. Continuation of usage of the website will be deemed that
            you have accepted these Terms of Use. These Terms of Use must be
            understood in conjunction with all applicable terms and warnings
            governing the use of the Website.
          </span>
          <h3 className="sideHeadings">Governing law</h3>
          <span className="descriptionInfo">
            This Terms of Service is governed by and operated in accordance with
            the laws of India. If any of the parties wish to seek legal
            recourse, they may do so by using the courts of law in Hyderabad.
          </span>
          <h3 className="sideHeadings">Miscellaneous</h3>
          <span className="descriptionInfo">
            If we are unable to use the Website use it in a prescribed manner
            described in the Terms of Use due to factors beyond our control
            including but not limited to any event of force majeure, change of
            the law of the jurisdiction, or change in sanctions policy, we shall
            not be liable for your use of the Website under these Terms of Use
            during the periods coincidental with the event. The failure of
            either party to exercise in any respect any right provided for
            herein shall not be deemed a waiver of any further rights hereunder.
          </span>
          <h3 className="sideHeadings">Changes to Terms of Use</h3>
          <span className="descriptionInfo">
            We reserve the right to change, add or remove parts of these Terms
            of Use at any time and at our sole discretion. You will be notified
            of any amendments through e-mail provided to the Company. On
            receiving notification, it is your responsibility to review the
            amended Terms of Use. Continuation of using of the Website
            subsequent to the posting of a notice of changes to the Terms
            signifies that you accept and agree to the changes thereof. <br />
            <br />
            IF YOU DO NOT ACCEPT THE TERMS OF USE, DO NOT ACCESS THIS WEBSITE.
            <br />
            <br />
            Questions and contact information To contact support, raise a
            support ticket. Please fill in all relevant fields and describe your
            problem briefly, providing all relevant details.
            <br />
            <br /> We will get back to you as soon as possible, however, we make
            no representations or provide warranties about the speed of
            response.
          </span>
        </div>
      </React.Fragment>
    );
  }
}

export default TermsOfService;
