import React, { Component } from "react";
import CloseNav from "../media/CloseNav.svg";
import { Link } from "react-router-dom";
import DropdownArrow from "../media/DropDown.svg";
import "../stylesheets/menubarMobile.css";
class menubarMobile extends Component {
  state = {
    isProductsSelected: false,
    activeProduct: this.props.activeProduct,
  };
  isProductsSelectedHandle = () => {
    let newState = { ...this.state };
    newState.isProductsSelected = !newState.isProductsSelected;
    this.setState(newState);
  };
  activeProductHandle = (activeProduct) => {
    let newState = { ...this.state };
    newState.activeProduct = activeProduct;
    this.setState(newState);
  };
  render() {
    let r = document.querySelector(":root");
    return (
      <div className="navMMainOuterDiv">
        <div className="navMMainInnerDiv">
          <div className="navMCloseDiv">
            <img
              src={CloseNav}
              className="navCloseIcon"
              alt="img"
              onTouchStartCapture={(event) => {
                this.props.menubarDisplayRemove();
              }}
              onClick={(event) => {
                this.props.menubarDisplayRemove();
              }}
            />
          </div>
          <div className="navMLinksdiv">
            {this.props.navlinks.map((linkName) => {
              let index = this.props.navlinks.indexOf(linkName);
              let path =
                linkName === "Home"
                  ? ""
                  : "/" + linkName.replace(" ", "").toLowerCase();
              return linkName === "Products" ? (
                <div key={index} className="navMProductsLinkDiv">
                  <div
                    className="navMProductsInnerDiv"
                    onClick={(event) => {
                      this.isProductsSelectedHandle();
                    }}
                  >
                    <Link className="navMProductLink">{linkName}</Link>
                    <img
                      src={DropdownArrow}
                      className="navMDropdownArrow"
                      alt="img"
                    />
                  </div>
                  {this.state.isProductsSelected && (
                    <div className="navMProductsListDiv">
                      {this.props.productsList.map((product) => {
                        let pIndex = this.props.productsList.indexOf(product);
                        let newPath =
                          path + "/" + product.replace(" ", "").toLowerCase();
                        return (
                          <Link
                            key={pIndex}
                            to={newPath}
                            className={
                              (this.state.activeProduct === product
                                ? "navMProductItemLinkActive "
                                : "") +
                              (this.props.productsList.length - 1 === pIndex
                                ? "navMProductItemLink navMPProductItemLinkLast"
                                : "navMProductItemLink")
                            }
                            onClick={(event) => {
                              this.props.activeLinkHandle("Products");
                              this.activeProductHandle(product);
                              this.props.menubarDisplayRemove();
                            }}
                          >
                            {product}
                          </Link>
                        );
                      })}
                    </div>
                  )}
                </div>
              ) : (
                <Link
                  key={index}
                  to={path}
                  className={
                    (this.props.activeLink === linkName
                      ? "navMLinkActive "
                      : "") +
                    (this.props.navlinks.length - 1 === index
                      ? "navMLinkLast navMLink"
                      : "navMLink")
                  }
                  onClick={(event) => {
                    if (this.props.activeLink !== linkName) {
                      this.props.activeLinkHandle(linkName);
                      this.activeProductHandle("");
                      this.props.menubarDisplayRemove();
                    }
                  }}
                >
                  {linkName}
                </Link>
              );
            })}
          </div>
        </div>
        <div
          className="navMClosingDiv"
          onTouchStartCapture={(event) => {
            this.props.menubarDisplayRemove();
          }}
          onClick={(event) => {
            this.props.menubarDisplayRemove();
          }}
        ></div>
      </div>
    );
  }
}

export default menubarMobile;
