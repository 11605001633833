import React, { Component } from "react";
import Navbar from "../components/navbar";
import "../stylesheets/security.css";
class Security extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <Navbar
          products={this.props.products}
          menubarDisplayActive={this.props.menubarDisplayActive}
          menubarDisplayRemove={this.props.menubarDisplayRemove}
          menubarDisplay={this.props.menubarDisplay}
        />
        <div className="securityMainDiv">
          <h1 className="mainHeading">Security</h1>
          <span className="descriptionInfo">
            According to BlockForTrust Solutions Private Limited (BFT) security
            clause, the company:
            <br />
            <ul>
              <li>Uses the strongest available browser encryption.</li>
              <li>Implements systematic processes.</li>
              <li>Stores all data in secure facilities.</li>
              <li>Limits access to materials on a need-to-know basis.</li>
              <li>Requires third parties to comply.</li>
              <li>
                Continues to protect the data of users even when customers close
                the accounts.
              </li>
            </ul>
            <br />
            We protect your Personal Data by taking appropriate physical,
            technical, and organizational safeguards and security measures. Your
            Personal Data reaches our servers through the internet which may
            choose its own routes and means, whereby information is traversed
            from location to location. Regular audit of our practices,
            procedures, and security measures are taken up to ensure proper
            administration and compliance while remaining effective and
            appropriate.
            <br />
            <br />
            Every member of Blockchain we deal with is committed to all of our
            privacy policies and procedures to safeguard all Personal Data.
            While this company takes all the above mentioned reasonable measures
            to guard against misuse of personal data/information submitted to it
            by you, this website at the same time cannot guarantee always that
            someone will not overcome our security measures, including, without
            limitation, the security measures implemented on this Web site.
            Therefore, the posting of your personal data or information on this
            Web site constitutes your acceptance of this implicit risk, and by
            posting personal data/information, you waive any right to seek legal
            relief /recourse from BlockForTrust Solutions Private Limited (BFT)
            due to any misuse of your information.
          </span>
        </div>
      </React.Fragment>
    );
  }
}

export default Security;
