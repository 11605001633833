import React, { Component } from "react";
import Navbar from "../components/navbar";
import "../stylesheets/products.css";
import BlockLeftBottom from "../media/BlockLeftBottom.svg";
import GetOnGooglePlay from "../media/GetOnGooglePlay.svg";
import ProductsBg1366x210 from "../media/ProductsBg1366x210.svg";
import ReactPlayer from "react-player";
import Footer from "../components/footer";
import FooterMobile from "../components/footerMobile";
import FooterMobile375 from "../components/footerMobile375";

class Products extends Component {
  state = {};

  render() {
    return (
      <div className="productsMainDiv">
        <Navbar
          products={this.props.products}
          menubarDisplayActive={this.props.menubarDisplayActive}
          menubarDisplayRemove={this.props.menubarDisplayRemove}
          menubarDisplay={this.props.menubarDisplay}
          activeLink="Products"
          activeProduct={this.props.productData.productTitle}
        />
        {window.innerWidth <= 768 ? (
          <div className="productsMHeaderDiv">
            <img
              src={
                "../media/Logo_" +
                this.props.productData.productTitle.replace(" ", "") +
                ".svg"
              }
              className="productsMLogo"
              alt="img"
            />
            <div className="productsMTitleNDescriptionDiv">
              <span className="productsMTitle">
                {this.props.productData.productTitle}
              </span>
              <span className="productsMDescription">
                {this.props.productData.productDescription}
              </span>
            </div>
          </div>
        ) : (
          <React.Fragment>
            <div className="productsBannerDiv">
              <img
                src={BlockLeftBottom}
                className="productsBgBlockImg"
                alt="img"
              />
              <img
                src={
                  "../media/Banner_" +
                  this.props.productData.productTitle.replace(" ", "") +
                  ".svg"
                }
                className="productsBgBannerImg"
                alt="img"
              />
            </div>
            <div className="productsIntroDiv">
              <img
                src={ProductsBg1366x210}
                className="productsBgImg"
                alt="img"
              />
              <div className="productsLogoNTitleDiv">
                <img
                  src={
                    "../media/Logo_" +
                    this.props.productData.productTitle.replace(" ", "") +
                    ".svg"
                  }
                  className="productsLogo"
                  alt="img"
                />
                <div className="productsTitleDiv">
                  <span className="productsTitle">
                    {this.props.productData.productTitle}
                  </span>
                  <span className="productsDescription">
                    {this.props.productData.productDescription}
                  </span>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
        <div className="productsTemplatesDiv">
          <div
            className={
              window.innerWidth <= 768
                ? "DisplayDiv"
                : "productsTemplatesDiv1366"
            }
          >
            {/* ---------------Template 7------------------- */}
            <div className="t7MainDiv">
              <span className="t7Title">{this.props.productData.heading1}</span>
              <span className="t7Body">{this.props.productData.body1}</span>
            </div>
            {/* ----------------Template 10---------------- */}
            <div className="t10MainDiv">
              <ReactPlayer
                className="videoPlayer"
                url={this.props.productData.productVideoLink}
                controls={true}
              />
            </div>
            {/* ---------------------Template 8--------------- */}
            <div className={"t8MainDiv"}>
              {this.props.productData.descriptionContent.map((infoDiv) => {
                return (
                  <div className={"t8SubDiv"}>
                    <div className="t8Heading">{infoDiv.heading}</div>
                    <div className="t8Bottombar"></div>
                    <span className="t8Description">{infoDiv.body}</span>
                  </div>
                );
              })}
            </div>
            {/* ---------------Template 9--------------- */}
            <div className="t9MainDiv">
              <a
                href={this.props.productData.productAppLink}
                target="_blank"
                rel="noreferrer"
              >
                <img src={GetOnGooglePlay} className="t9button" alt="img" />
              </a>
            </div>
            {window.innerWidth <= 375 ? (
              <FooterMobile375 ContactData={this.props.ContactData} />
            ) : window.innerWidth <= 768 ? (
              <FooterMobile ContactData={this.props.ContactData} />
            ) : (
              <Footer ContactData={this.props.ContactData} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Products;
