import React, { Component } from "react";
import BlockLeftBottom from "../media/BlockLeftBottom.svg";
import BlockRightBottom from "../media/BlockRightBottom.svg";
import BlockLeftUp from "../media/BlockLeftUp.svg";
import AboutUsImg from "../media/AboutUsImg.svg";
import Navbar from "../components/navbar";
import AboutUs1 from "../media/AboutUs-1.svg";
import AboutUs2 from "../media/AboutUs-2.svg";
import AboutUs3 from "../media/AboutUs-3.svg";
import "../stylesheets/aboutus.css";

class AboutUs extends Component {
  state = {};
  t3content = [
    {
      image: AboutUs1,
      body: "BFT transforms enterprises by providing Blockchain solutions embedded with best-in-class experiences, driven by strong technology for achieving business excellence.",
      buttonDisplay: false,
      buttonText: "GET IN TOUCH",
    },
    {
      image: AboutUs2,
      body: "Our aim is to partner with businesses that share our passion for distributed ledger technology (DLT)",
      buttonDisplay: false,
      buttonText: "GET IN TOUCH",
    },
    {
      image: AboutUs3,
      body: "Define a new future and direction in this global era of Blockchain innovation.",
      buttonDisplay: false,
      buttonText: "GET IN TOUCH",
    },
  ];

  render() {
    return (
      <div className="aboutUsMainDiv">
        <Navbar
          products={this.props.products}
          menubarDisplayActive={this.props.menubarDisplayActive}
          menubarDisplayRemove={this.props.menubarDisplayRemove}
          menubarDisplay={this.props.menubarDisplay}
          activeLink="About Us"
        />
        {/* -----------Template 1---------------- */}
        <div className="t1MainDiv">
          <img src={BlockLeftBottom} className="t1bgImg1" alt="img" />
          <img src={BlockLeftUp} className="t1bgImg2" alt="img" />
          <div className="t1ContentDiv">
            <span className="t1Heading">{"Who we are?"}</span>
            <span className="t1Body">
              {
                "BlockForTrust (BFT) is revolutionizing business practices and fueling the growth of organizations with Blockchain global solutions. We are global leaders with extensive experience in creating customized, trusted end-to-end Blockchain solutions and services that help businesses to create an everlasting impact in this new era of digital transformation. We offer a wide range of innovative Blockchain value added services to help businesses in the adoption, integration and realization of Blockchain networks."
              }
            </span>
          </div>
          <div className="t1ImgDiv">
            <img src={AboutUsImg} alt="img" />
          </div>
        </div>
        {/* -----------------Template 3----------------- */}
        <div className="t3MainDiv">
          <img src={BlockRightBottom} className="t3bgImg1" alt="img" />
          <div className="t3HeadingDiv">
            <span className="t3HeadingText">{"Our Motto"}</span>
            <span className="t3DescriptionText">
              {
                "Our passionate enthusiastic team of qualified professionals bring in new ideas and work together with a motto to ‘Build the Trust in Networks’"
              }
            </span>
          </div>
          <div className="t3ContentDiv">
            {this.t3content.map((object) => {
              return (
                <div
                  key={this.t3content.indexOf(object)}
                  className={"t3InfoMainDiv " + this.t3content.indexOf(object)}
                >
                  <img alt="img" src={object.image} />
                  <span className="t3SubDesc">{object.body}</span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default AboutUs;
