import "./App.css";
import React, { Component } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import ScrollToTop from "./components/scrollToTop";
import Home from "./containers/Home";
import Collaboration from "./containers/Collaboration";
import AboutUs from "./containers/AboutUs";
import Contact from "./containers/Contact";
import Products from "./containers/Products";
import Footer from "./components/footer";
import FooterMobile from "./components/footerMobile";
import FooterMobile375 from "./components/footerMobile375";
import TermsOfService from "./containers/TermsOfService";
import PrivacyPolicy from "./containers/PrivacyPolicy";
import Security from "./containers/Security";
// -----Json files----
import ContactData from "./contact.json";
import ProductsData from "./products.json";
class App extends Component {
  state = {
    menubarDisplay: false,
  };
  render() {
    //fetch products,contactData from json
    let products = [];
    ProductsData.products.map((product) => {
      products.push(product.productTitle);
      return null;
    });
    let menubarDisplayActive = () => {
      let newState = { ...this.state };
      newState.menubarDisplay = true;
      this.setState(newState);
    };
    let menubarDisplayRemove = () => {
      let newState = { ...this.state };
      newState.menubarDisplay = false;
      this.setState(newState);
    };
    return (
      <div className={this.state.menubarDisplay ? "AppNoScroll" : "App"}>
        <div
          className={window.innerWidth <= 768 ? "DisplayDiv" : "Display1366Div"}
        >
          <BrowserRouter>
            <ScrollToTop>
              <Switch>
                <Route exact path="/">
                  <Home
                    products={products}
                    menubarDisplayActive={menubarDisplayActive}
                    menubarDisplayRemove={menubarDisplayRemove}
                    menubarDisplay={this.state.menubarDisplay}
                    ProductsData={ProductsData}
                  />
                </Route>
                <Route exact path="/collaboration">
                  <Collaboration
                    products={products}
                    menubarDisplayActive={menubarDisplayActive}
                    menubarDisplayRemove={menubarDisplayRemove}
                    menubarDisplay={this.state.menubarDisplay}
                  />
                </Route>
                {/* {ProductsData.products.map((product) => {
                  return (
                    <Route
                      exact
                      path={
                        "/products/" + product.productTitle.replace(" ", "")
                      }
                      component={() => (
                        <Products
                          products={products}
                          menubarDisplayActive={menubarDisplayActive}
                          menubarDisplayRemove={menubarDisplayRemove}
                          menubarDisplay={this.state.menubarDisplay}
                          ContactData={ContactData}
                          productData={product}
                        />
                      )}
                    />
                  );
                })} */}

                <Route
                  exact
                  path="/aboutus"
                  component={() => (
                    <AboutUs
                      products={products}
                      menubarDisplayActive={menubarDisplayActive}
                      menubarDisplayRemove={menubarDisplayRemove}
                      menubarDisplay={this.state.menubarDisplay}
                    />
                  )}
                />
                <Route
                  exact
                  path="/about-us"
                  component={() => (
                    <AboutUs
                      products={products}
                      menubarDisplayActive={menubarDisplayActive}
                      menubarDisplayRemove={menubarDisplayRemove}
                      menubarDisplay={this.state.menubarDisplay}
                    />
                  )}
                />
                <Route
                  exact
                  path="/contact"
                  component={() => (
                    <Contact
                      products={products}
                      menubarDisplayActive={menubarDisplayActive}
                      menubarDisplayRemove={menubarDisplayRemove}
                      menubarDisplay={this.state.menubarDisplay}
                      ContactData={ContactData}
                    />
                  )}
                />
                <Route exact path="/termsofservice">
                  <TermsOfService
                    products={products}
                    menubarDisplayActive={menubarDisplayActive}
                    menubarDisplayRemove={menubarDisplayRemove}
                    menubarDisplay={this.state.menubarDisplay}
                  />
                </Route>
                <Route exact path="/privacypolicy">
                  <PrivacyPolicy
                    products={products}
                    menubarDisplayActive={menubarDisplayActive}
                    menubarDisplayRemove={menubarDisplayRemove}
                    menubarDisplay={this.state.menubarDisplay}
                  />
                </Route>
                <Route exact path="/security">
                  <Security
                    products={products}
                    menubarDisplayActive={menubarDisplayActive}
                    menubarDisplayRemove={menubarDisplayRemove}
                    menubarDisplay={this.state.menubarDisplay}
                  />
                </Route>
              </Switch>

              {window.innerWidth <= 540 ? (
                <FooterMobile375 ContactData={ContactData} />
              ) : window.innerWidth <= 768 ? (
                <FooterMobile ContactData={ContactData} />
              ) : (
                <Footer ContactData={ContactData} />
              )}
            </ScrollToTop>
          </BrowserRouter>
        </div>
      </div>
    );
  }
}

export default App;
