import React, { Component } from "react";
import { Link } from "react-router-dom";
import Logo from "../media/Logo.svg";
import BurgerMenu from "../media/BurgerMenu.svg";
import "../stylesheets/navbar.css";
import MenubarMobile from "./menubarMobile";
class navbar extends Component {
  state = {
    productsActive: false,
    activeLink: this.props.activeLink,
    // navlinks: ["Home", "Collaboration", "Products", "About Us", "Contact"],
    navlinks: ["Home", "Collaboration", "About Us", "Contact"],
  };

  navActiveLinkHandle = (activeLinkProp) => {
    let newState = { ...this.state };
    newState.activeLink = activeLinkProp;
    this.setState(newState);
  };

  getDropDown = () => {
    let r = document.querySelector(":root");
    return (
      <div
        className="productsDropDownMainDiv"
        onMouseEnter={() => {
          r.style.setProperty("--display", "flex");
        }}
        onMouseLeave={() => {
          r.style.setProperty("--display", "none");
        }}
      >
        {this.props.products.map((product) => {
          let index = this.props.products.indexOf(product);
          return (
            <Link
              to={"/products/" + product.replace(" ", "").toLowerCase()}
              key={index}
              className="productsListItem"
              onClick={(event) => {
                let newState = { ...this.state };
                newState.activeLink = "Products";
                this.setState(newState);
                if (window.innerWidth <= 1080) {
                  r.style.setProperty("--display", "none");
                }
              }}
            >
              {product}
            </Link>
          );
        })}
      </div>
    );
  };

  render() {
    return (
      <div className="navMainDiv">
        <Link to="/">
          <img src={Logo} alt="img" />
        </Link>
        {window.innerWidth <= 768 ? (
          <div className="navBurgerMenuDiv">
            <img
              src={BurgerMenu}
              className="navBurgerMenu"
              alt="img"
              onClick={(event) => {
                this.props.menubarDisplayActive();
              }}
            />
          </div>
        ) : (
          <div className="navLinksDiv">
            {this.state.navlinks.map((linkName) => {
              let path =
                linkName === "Home"
                  ? "/"
                  : "/" + linkName.replace(" ", "").toLowerCase();
              if (linkName === "Products") {
                let r = document.querySelector(":root");
                return (
                  <div
                    key={linkName}
                    className="navProductsDiv"
                    onMouseEnter={() => {
                      r.style.setProperty("--display", "flex");
                    }}
                    onMouseLeave={() => {
                      r.style.setProperty("--display", "none");
                    }}
                  >
                    <Link
                      key={linkName}
                      to="#"
                      className={
                        this.state.activeLink === "Products"
                          ? "navActiveLink navLinkProducts"
                          : "navLinkProducts"
                      }
                      onClick={(event) => {
                        if (window.innerWidth <= 1080) {
                          r.style.setProperty("--display", "flex");
                        }
                      }}
                    >
                      {linkName}
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 24 24"
                        className="Icon__StyledIcon-sc-1o8zi5s-0 Dropdown__StyledIcon-sc-1yd08gi-0 vJgQg ihRcWe"
                        height="20"
                        width="20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
                      </svg>
                    </Link>
                    {this.getDropDown(r)}
                  </div>
                );
              } else
                return (
                  <React.Fragment key={linkName}>
                    <Link
                      key={linkName}
                      to={path}
                      className={
                        this.state.activeLink === linkName
                          ? "navActiveLink"
                          : "navLink"
                      }
                    >
                      {linkName}
                    </Link>
                  </React.Fragment>
                );
            })}
          </div>
        )}
        {this.props.menubarDisplay && (
          <MenubarMobile
            menubarDisplayRemove={this.props.menubarDisplayRemove}
            navlinks={this.state.navlinks}
            productsList={this.props.products}
            activeLink={this.state.activeLink}
            activeLinkHandle={this.navActiveLinkHandle}
            activeProduct={this.props.activeProduct}
          />
        )}
      </div>
    );
  }
}

export default navbar;
