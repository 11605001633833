import React, { Component } from "react";
import Navbar from "../components/navbar";
import "../stylesheets/privacyPolicy.css";
class PrivacyPolicy extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <Navbar
          products={this.props.products}
          menubarDisplayActive={this.props.menubarDisplayActive}
          menubarDisplayRemove={this.props.menubarDisplayRemove}
          menubarDisplay={this.props.menubarDisplay}
        />
        <div className="privacyPolicyMainDiv">
          <h1 className="mainHeading">Privacy Policy</h1>
          <span className="descriptionInfo">
            Privacy is of utmost importance at BlockForTrust Solutions Private
            Limited (BFT). We understand the significance of protecting the
            information that is stored in our servers or network or is intended
            to be stored on our servers or network and which directly or
            indirectly relates to an individual.
            <br />
            <br />
            This Privacy Policy is designed to make you feel confident and help
            you understand the privacy and security of your personal details.
            'You' means You, the User of the Website/ Application, and
            ‘Yourself’ is interpreted accordingly. 'We' / 'Us' means
            BlockForTrust Solutions Private Limited (BFT) and 'Our' is
            interpreted accordingly. 'Users' means the Users of the application
            Website/ collectively and/or individually as in the context.
            <br />
            <br />
            This Privacy Policy applies to all the information collected by the
            Company through the Website or application. The purpose of this
            Privacy policy is to help you get a clear explanation of the process
            of how we collect and process your personal information. This policy
            may be subjected to change over time by the updating of this page.
            We commit to ensuring that your privacy will be protected. Any
            information that is collected through the website/application by
            which you can be identified will only be used according to this
            privacy policy.
          </span>
          <h3 className="sideHeading">The Information we collect</h3>
          <span className="descriptionInfo">
            When you access our website or use the Services, we collect the
            following information:
            <h4 className="inSideHeading">
              Information You May Provide to Us:
            </h4>
            <span className="descriptionInfo">
              You might give us your personal information while filling in forms
              on our website or through our app or when corresponding with us by
              phone, email, or any other media. This also includes the
              information that you provide when you register to use the Services
              and while reporting issues when you face any error/problem with
              the website or with our app.
            </span>
            <h4 className="inSideHeading">
              Information We Automatically Collect About You:
            </h4>
            <span className="descriptionInfo">
              Concerning each of your visits to our website or our app we
              automatically collect the following information
            </span>
          </span>
          <h3 className="sideHeading">
            How the company may use your information
          </h3>
          <h4 className="insideHeading">Personal Information</h4>
          <span className="descriptionInfo">
            The Company identifies the aim that your Personal Information is
            collected and can be used. <br />
            By using the features of the Website which require you to provide us
            with your e-mail address, you will be deemed to consent to our use
            of your Personal Information for
            <ul>
              <li>
                Monitoring your use of our website and improve the user
                experience;
              </li>
              <li>
                Analyzing the trends and web traffic, purchases metadata, and
                track usage of data;
              </li>
              <li>
                Processing the tickets raised for support and responding to your
                queries;
              </li>
              <li>
                keeping track of communication regarding tickets submitted for
                support and reporting back to you;
              </li>
              <li>
                Sending emails, updates, Policy changes newsletters, mailing
                updates.
              </li>
            </ul>
            If any need arises to use your personal data for any other purpose
            or for a purpose not related to the reasons for which we collected
            the data, we will notify you and help you know the legal ground of
            processing. Please note that BlockForTrust Solutions Private Limited
            (BFT) will get your express consent (by verbal, written, or
            electronic agreement) in order to collect, use or disclose your
            Personal Information.
            <br />
            <br />
            We use a third-party provider to deliver our newsletters. We’ll
            strive to treat your personal data with care. You can update your
            email address or unsubscribe from our mailing list whenever required
            by following the process mentioned for unsubscribing.
            <br />
            <br />
            We will use your Personal Data, to:
            <ul>
              <li>
                Understand and get a better experience of our services to meet
                your needs and preferences;
              </li>
              <li>
                Develop new features and enhance existing services and product
                offerings;
              </li>
              <li> Manage and develop our business and operations thereof ;</li>
              <li>Perform any action for which the consent is received;</li>
              <li>
                Prevent, investigate and follow up fraudulent or other criminal
                activity;
              </li>
              <li>
                To address any service requests raised and solve user questions;
                and
              </li>
              <li>Meet legal and regulatory requirements.</li>
            </ul>
            <br />
            We also reserve the right to use aggregated Personal Data to get
            cognizance of how our users use our Services, notwithstanding that
            the data collected cannot identify any individual.
            <br />
            <br />
            <h4 className="insideHeading">Non-Personal Information</h4>
            The Company reserves right to use Non-Personal Information for
            system administration. The Company may use Non-Personal Information
            for system administration, to assist diagnose problems with the
            Company servers, monitoring the Company’s system performance,
            traffic on the Website and gather broader demographic details of
            visitors of the Website.
          </span>
          <br />
          <br />
          <h3 className="sideHeading">Use of Cookies</h3>
          <span className="descriptionInfo">
            We use "cookies" while you use our website to improve and
            personalize your online experience with us. A cookie is a small text
            file that is stored on your computer/device to make your visit to
            our site more “user-friendly.” Please see our Cookies Policy for
            more details regarding the cookies we use. Cookies provide us with
            information about your usage of the site that may help us improve
            the working of the site and your experience with it. We reserve the
            right to process Personal Data collected through cookies in
            accordance with this Privacy Policy. If alerts are set in your
            browser to ask you before accepting cookies, you would receive an
            alert message with each cookie. You may also refuse cookies by
            simply turning them off in your browser, however, you should
            acknowledge that our site, like most other popular sites, may not
            work well with cookies disabled.
            <ul>
              <li>
                Banks or payment gateways that are used to transact while
                transferring fiat currency may provide us with basic Personal
                Data, such as your name and address including your bank account
                information.
              </li>
              <li>
                Advertising or analytics service providers may provide us with
                any anonymous information about you, including but not limited
                to, how you reached our website.
              </li>
            </ul>
          </span>
          <h3 className="sideHeading">Storage of Data</h3>
          <span className="descriptionInfo">
            We always follow generally accepted industry standards while
            collecting storing and protecting personal data including the use of
            encryption. We store personal data for as long as it is needed to
            provide the services you have been subscribed to, and thereafter for
            matters of legal and service purposes. This duration may include
            retention periods as required by legal, contractual, or similar
            obligations; for resolving, preserving, enforcing, or defending our
            legal and contractual rights which are needed to maintain adequate
            and compliant business practices and financial records or how you
            can access, update or delete your data, etc.
            <br />
            <br />
            We protect your Personal Data by taking appropriate physical,
            technical, and organizational safeguards and security measures. Your
            Personal Data reaches our servers via the internet which may choose
            its own routes and means, whereby information is traversed from
            location to location. Regular audit of our practices, procedures,
            and security measures are taken up to ensure proper administration
            and compliance while remaining effective and appropriate.
            <br />
            Every member of Blockchain we deal with is committed to all of our
            privacy policies and procedures to safeguard all Personal Data.
            While this company takes all the above mentioned reasonable measures
            to guard against misuse of personal data/information submitted to it
            by you, this website at the same time cannot guarantee always that
            someone will not overcome our security measures, including, without
            limitation, the security measures implemented on this Web site.
            Therefore, the posting of your personal data/ information on this
            Web site constitutes your acceptance of this implicit risk, and by
            posting personal data/information, you waive any right to seek legal
            relief /recourse from BlockForTrust Solutions Private Limited (BFT)
            due to any misuse of your information
          </span>
          <h3 className="sideHeading">Information Sharing and Disclosures</h3>
          <span className="descriptionInfo">
            With third parties to investigate, prevent, or take action (in our
            sole discretion) in cases of potentially illegal activities,
            suspected fraud, situations involving potential threats to any
            person, us, or the Website, or violations of our policies, our Terms
            of Use or law to verify or enforce compliance with the policies
            governing our Website.
            <br />
            We may share the User Information with Our affiliates/companies, so
            they can provide, improve and communicate with You about their own,
            or their marketing partners’ products and services. We reserve the
            right to disclose information and transfer User data outside India.
            We will comply with all relevant Data Protection legislation
            concerning the period for which We retain any User Information.
          </span>
          <h3 className="sideHeading">Other Sites </h3>
          <span className="descriptionInfo">
            Our Website may contain links to or integrations with other services
            such as Facebook, Twitter, Linked In, and other media services and
            platforms whose information practices may be different than ours.
            Visitors should consult these other third-party services' privacy
            policies as we do not have any control over information that is
            submitted to, or collected by, these third parties.
          </span>
          <h3 className="sideHeading">Acceptance</h3>
          <span className="descriptionInfo">
            By using the Services, you agree to sign this Privacy Policy.
            BlockForTrust Solutions Private Limited (BFT) reserves the right to
            change or amend this Privacy Policy at any time. If we make any
            material changes to this Privacy Policy, the revised Policy will be
            posted here and we will notify our users through any electronic
            communication not limiting to email before the changes taking effect
            so that you will be aware of what information we collect, how we use
            it and under what circumstances/Conditions we disclose it. Please
            check this page frequently for any updates or changes to this
            Privacy Policy.
          </span>
          <h3 className="sideHeading">Changes to Privacy Policy</h3>
          <span className="descriptionInfo">
            We reserve all the rights to update this Privacy Policy going
            forward. If there are any material changes to how we handle personal
            information, we will send you a notification e-mail and/or place a
            temporary notice on our website.
          </span>
          <h3 className="sideHeading">Contacting us</h3>
          <span className="descriptionInfo">
            If you have any questions, feedback, or comments about this Privacy
            Policy, the practices of this Website, or your dealings with this
            site, please email us at{" "}
            <span className="emailHighlight">team@blockfortrust.com</span>.
          </span>
        </div>
      </React.Fragment>
    );
  }
}

export default PrivacyPolicy;
