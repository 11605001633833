import React, { Component } from "react";
import { Link } from "react-router-dom";

import "../stylesheets/button.css";
class button extends Component {
  state = {};
  render() {
    return (
      <Link to={this.props.path}>
        <button className="button">{this.props.buttonText}</button>
      </Link>
    );
  }
}

export default button;
